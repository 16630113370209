import * as React from 'react'
import partyBoston from '../../static/images/bp-photos/bridgephase-boston-holiday-party-2019.jpg'
import imgPhotobooth from '../../static/images/bp-photos/photobooth.jpg'
import imgMudGroup from '../../static/images/bp-photos/tough-mudder-group.jpg'
import { DefaultLayout } from '../layouts/main-layout'

const COLLAGE_PHOTOS = [
  {
    image: partyBoston,
    description: 'BridgePhase Boston Holiday Party',
    rotate: '-15deg',
  },
  { image: imgMudGroup, description: 'Tough Mudder 2019', rotate: '6deg' },
  {
    image: '/images/stock/bridgephase-team.jpg',
    description: 'BridgePhase DC Holiday Party 2022',
    rotate: '15deg',
  },
  {
    image: '/images/stock/ping-pong.jpg',
    description: 'Ping-Pong @ BridgePhase',
    rotate: '-23deg',
  },
  {
    image: imgPhotobooth,
    description: 'Photobooth Time! 📸',
    rotate: '-19deg',
  },
  {
    image: '/images/stock/get-the-spare.jpg',
    description: 'Bowling @ BridgePhase',
    rotate: '30deg',
  },
]

const PhotoCollage = () => (
  <div>
    {COLLAGE_PHOTOS.map((photoImg: any, index: number) => (
      <div
        className="polaroid"
        style={{
          transform: `rotate(${photoImg.rotate})`,
        }}
      >
        <img src={photoImg.image} key={photoImg.description} title={photoImg.description} alt={photoImg.description} />
        <div className="caption">{photoImg.description}</div>
      </div>
    ))}
  </div>
)

function JazzWrapper() {
  const ref = React.useRef<HTMLIFrameElement>()
  return (
    <iframe
      title="BridgePhase open positions"
      ref={ref}
      id="myFrame"
      src="//bridgephase.applytojob.com/apply/jobs/"
      width="900"
      style={{
        width: '100%',
        maxHeight: '800px',
        height: '500px',
        overflow: 'auto',
      }}
    ></iframe>
  )
}

const CareersPage = () => (
  <DefaultLayout name="Careers" description="Careers at BridgePhase: Our culture and job openings.">
    <h1 className="page-header">Careers</h1>

    <section>
      <h2>Our Culture</h2>
      <div className="mb-8 mt-8 mx-auto w-full md:w-2/3 lg:w-1/2">
        <iframe
          className="w-full aspect-video"
          id="ytplayer"
          typeof="text/html"
          src="https://www.youtube.com/embed/NWl9k22HTbc"
          allowFullScreen
        ></iframe>
      </div>

      <p className="mb-8">
        Your experience at BridgePhase will involve fast paced challenging work, outstanding benefits, career growth
        opportunities, access to project and company social functions, professional development, and community outreach
        activities.
      </p>
      <PhotoCollage />
    </section>

    <section className="responsive-container mt-8">
      <h2 className="mt-8">Join Our Team</h2>
      <div className="main-content">
        <p>
          We are looking for highly motivated IT professionals who are driven by helping their clients achieve their
          mission.
        </p>
        <p>
          Our current job openings are listed below. If you don’t see a position that matches your skillset, you can
          register with us and we’ll notify when a relevant position becomes available.
        </p>

        <JazzWrapper />
        <a href="//bridgephase.applytojob.com/apply/jobs/">View all jobs</a>
      </div>
      <div
        className="img-content"
        style={{ backgroundImage: `url('/images/stock/fun-at-work.jpg')` }}
        title="Highly motivated IT professionals"
      />
    </section>
  </DefaultLayout>
)

export default CareersPage
